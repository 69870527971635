import { Box, Heading, LinkBox, LinkOverlay, SimpleGrid, Stack } from '@chakra-ui/react'
// import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import parse from "html-react-parser"

import { responsive } from '../components/ThemeProvider/theme'
import Link from '../components/Link'
import BackgroundImage from '../components/BackgroundImage'
import more from './read-more.svg'

const PostArchive = ({ posts, ...props }) => {
  return (
    <SimpleGrid
      as={`ol`}
      style={{ listStyle: `none` }}
      columns={[1, 1, 2, 3]}
      spacing={8}
      {...props}
    >
      {posts.map((post, i) => {
        const title = post.title
        return (
          <li key={post.uri}>
            <Stack
              as={p => <LinkBox {...p} as="article" />}
              itemScope
              itemType="http://schema.org/Article"
              px={responsive('1em', '3em')}
              py={responsive('1.5em', '1em')}
              borderBottom={responsive(i !== posts.length - 1 && '1px solid black', 'none')}
              borderLeft={['none', null, i % 2 && '1px solid black', i % 3 && '1px solid black']}
              spacing="1em"
              letterSpacing="widest"
            >
              <Stack as="header" spacing="0.5em">
                <LinkOverlay as={Link} to={post.uri} itemProp="url">
                  <Heading fontSize={responsive('1.5rem', '2rem')} color="black">
                    <span itemProp="headline">{parse(title)}</span>
                  </Heading>
                </LinkOverlay>
                {/* <small>By {post.author?.node?.name} | {post.date}</small> */}
              </Stack>
              <Box
                as="section"
                itemProp="description"
                textAlign="justify"
                lineHeight="1.75"
              >{parse(post.excerpt)}</Box>
              <BackgroundImage ml="auto" width={responsive('8rem', '10rem')} ratio={314 / 50} src={more} />
            </Stack>
          </li>
        )
      })}
    </SimpleGrid>
  )
}

export default PostArchive
