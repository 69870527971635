import React from 'react'

import SVG from './SVG'

const Star = (props) => {
  return (
    <SVG viewBox="0 0 90 90" {...props}>
      <path fill="currentColor" d="M45,.93C46.42,42.43,47.57,43.58,89.07,45,47.57,46.42,46.42,47.57,45,89.07,43.58,47.57,42.43,46.42.93,45,42.43,43.58,43.58,42.43,45,.93"/>
    </SVG>
  )
}

export default Star
