import { Box, Heading, HStack } from '@chakra-ui/react'
import React from 'react'

import Star from '../../components/Star'
import { responsive } from '../../components/ThemeProvider/theme'

const StarTitle = ({
  children,
  ...props
}) => {
  return (
    <HStack pos="relative" fontSize={responsive('1.5em', '2em')} justifyContent="center" spacing={responsive(2, 4)} {...props}>
      <Star w="1.25em" />
      <Box flex="1" borderBottom="1px solid" />
      <Heading letterSpacing="widest" color="black" fontWeight={400} fontSize="1em">{children}</Heading>
      <Box flex="1" borderBottom="1px solid" />
      <Star w="1.25em" />
    </HStack>
  )
}

export default StarTitle
